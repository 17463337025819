import React ,{useState}from 'react'
import { Link } from 'react-router-dom'
import './header.css'
import laptop from '../../assets/laptop.svg'

import LoanSlider from './LoanSlider'



const Header = () => {

   const [input,setInput] = useState(1000)

   const handleChange = (e) => {
    setInput(e.target.value);
    console.log("this is the parent speaking")
  };

  return (
    <header>
    <div className="container header__container-1">
      

      <div className="headerExplainer">
      <h1 className="text-light"> Software Development Agency</h1>

         <p className="text-light">
          Neallus Labs is a leading software agency that specializes in delivering
          custom-built software applications tailored to meet the unique
           needs of businesses across various industries.
        </p>

         <button className="btn btn-primary">
           Explore
         </button>
      </div>
     
     <div className='headerCTA'>
     <img  className='CTAboximg' src={laptop} alt="software design laptop"/>
     
     </div>

    </div>
    </header>
  )
}

export default Header